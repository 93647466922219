// import { useRouter } from "next/router";
// const pluralize = require('pluralize')
function pluralizeCustom(word, count) {
  // If the count is 1, return the singular word
  if (count === 1) {
    return word;
  }

  // For count greater than 1, add 's' at the end to make it plural
  return word + 's';
}

// const XDate = require('xdate')
import _round from 'lodash/round';
import _isNumber from 'lodash/isNumber';
import _map from 'lodash/map';
import _split from 'lodash/split';
import _last from 'lodash/last';
import _size from 'lodash/size';

const zScoreAreas = function () {
  var azScoreArea = [];
  azScoreArea[-400] = 0.000031686;
  azScoreArea[-399] = 0.0000330518;
  azScoreArea[-398] = 0.0000344732;
  azScoreArea[-397] = 0.0000359523;
  azScoreArea[-396] = 0.0000374913;
  azScoreArea[-395] = 0.0000390925;
  azScoreArea[-394] = 0.0000407581;
  azScoreArea[-393] = 0.0000424907;
  azScoreArea[-392] = 0.0000442927;
  azScoreArea[-391] = 0.0000461668;
  azScoreArea[-390] = 0.0000481155;
  azScoreArea[-389] = 0.0000501418;
  azScoreArea[-388] = 0.0000522484;
  azScoreArea[-387] = 0.0000544383;
  azScoreArea[-386] = 0.0000567147;
  azScoreArea[-385] = 0.0000590806;
  azScoreArea[-384] = 0.0000615394;
  azScoreArea[-383] = 0.0000640944;
  azScoreArea[-382] = 0.0000667491;
  azScoreArea[-381] = 0.0000695072;
  azScoreArea[-380] = 0.0000723724;
  azScoreArea[-379] = 0.0000753486;
  azScoreArea[-378] = 0.0000784397;
  azScoreArea[-377] = 0.0000816499;
  azScoreArea[-376] = 0.0000849834;
  azScoreArea[-375] = 0.0000884446;
  azScoreArea[-374] = 0.000092038;
  azScoreArea[-373] = 0.0000957684;
  azScoreArea[-372] = 0.0000996405;
  azScoreArea[-371] = 0.000103659;
  azScoreArea[-370] = 0.00010783;
  azScoreArea[-369] = 0.000112158;
  azScoreArea[-368] = 0.000116649;
  azScoreArea[-367] = 0.000121308;
  azScoreArea[-366] = 0.000126141;
  azScoreArea[-365] = 0.000131154;
  azScoreArea[-364] = 0.000136353;
  azScoreArea[-363] = 0.000141746;
  azScoreArea[-362] = 0.000147337;
  azScoreArea[-361] = 0.000153135;
  azScoreArea[-360] = 0.000159146;
  azScoreArea[-359] = 0.000165377;
  azScoreArea[-358] = 0.000171836;
  azScoreArea[-357] = 0.00017853;
  azScoreArea[-356] = 0.000185467;
  azScoreArea[-355] = 0.000192656;
  azScoreArea[-354] = 0.000200105;
  azScoreArea[-353] = 0.000207822;
  azScoreArea[-352] = 0.000215816;
  azScoreArea[-351] = 0.000224097;
  azScoreArea[-350] = 0.000232673;
  azScoreArea[-349] = 0.000241555;
  azScoreArea[-348] = 0.000250753;
  azScoreArea[-347] = 0.000260276;
  azScoreArea[-346] = 0.000270135;
  azScoreArea[-345] = 0.000280341;
  azScoreArea[-344] = 0.000290906;
  azScoreArea[-343] = 0.00030184;
  azScoreArea[-342] = 0.000313156;
  azScoreArea[-341] = 0.000324865;
  azScoreArea[-340] = 0.000336981;
  azScoreArea[-339] = 0.000349515;
  azScoreArea[-338] = 0.000362482;
  azScoreArea[-337] = 0.000375895;
  azScoreArea[-336] = 0.000389767;
  azScoreArea[-335] = 0.000404113;
  azScoreArea[-334] = 0.000418948;
  azScoreArea[-333] = 0.000434286;
  azScoreArea[-332] = 0.000450144;
  azScoreArea[-331] = 0.000466538;
  azScoreArea[-330] = 0.000483483;
  azScoreArea[-329] = 0.000500996;
  azScoreArea[-328] = 0.000519095;
  azScoreArea[-327] = 0.000537798;
  azScoreArea[-326] = 0.000557122;
  azScoreArea[-325] = 0.000577086;
  azScoreArea[-324] = 0.000597711;
  azScoreArea[-323] = 0.000619014;
  azScoreArea[-322] = 0.000641016;
  azScoreArea[-321] = 0.000663738;
  azScoreArea[-320] = 0.000687202;
  azScoreArea[-319] = 0.000711429;
  azScoreArea[-318] = 0.00073644;
  azScoreArea[-317] = 0.00076226;
  azScoreArea[-316] = 0.000788912;
  azScoreArea[-315] = 0.000816419;
  azScoreArea[-314] = 0.000844806;
  azScoreArea[-313] = 0.000874099;
  azScoreArea[-312] = 0.000904323;
  azScoreArea[-311] = 0.000935504;
  azScoreArea[-310] = 0.000967671;
  azScoreArea[-309] = 0.001000851;
  azScoreArea[-308] = 0.001035071;
  azScoreArea[-307] = 0.001070363;
  azScoreArea[-306] = 0.001106754;
  azScoreArea[-305] = 0.001144276;
  azScoreArea[-304] = 0.00118296;
  azScoreArea[-303] = 0.001222838;
  azScoreArea[-302] = 0.001263943;
  azScoreArea[-301] = 0.001306308;
  azScoreArea[-300] = 0.001349967;
  azScoreArea[-299] = 0.001394956;
  azScoreArea[-298] = 0.001441311;
  azScoreArea[-297] = 0.001489068;
  azScoreArea[-296] = 0.001538264;
  azScoreArea[-295] = 0.001588938;
  azScoreArea[-294] = 0.001641129;
  azScoreArea[-293] = 0.001694878;
  azScoreArea[-292] = 0.001750225;
  azScoreArea[-291] = 0.001807211;
  azScoreArea[-290] = 0.00186588;
  azScoreArea[-289] = 0.001926276;
  azScoreArea[-288] = 0.001988442;
  azScoreArea[-287] = 0.002052424;
  azScoreArea[-286] = 0.00211827;
  azScoreArea[-285] = 0.002186026;
  azScoreArea[-284] = 0.00225574;
  azScoreArea[-283] = 0.002327463;
  azScoreArea[-282] = 0.002401244;
  azScoreArea[-281] = 0.002477136;
  azScoreArea[-280] = 0.002555191;
  azScoreArea[-279] = 0.002635461;
  azScoreArea[-278] = 0.002718003;
  azScoreArea[-277] = 0.002802872;
  azScoreArea[-276] = 0.002890125;
  azScoreArea[-275] = 0.002979819;
  azScoreArea[-274] = 0.003072013;
  azScoreArea[-273] = 0.003166769;
  azScoreArea[-272] = 0.003264148;
  azScoreArea[-271] = 0.003364211;
  azScoreArea[-270] = 0.003467023;
  azScoreArea[-269] = 0.003572649;
  azScoreArea[-268] = 0.003681155;
  azScoreArea[-267] = 0.003792607;
  azScoreArea[-266] = 0.003907076;
  azScoreArea[-265] = 0.004024631;
  azScoreArea[-264] = 0.004145342;
  azScoreArea[-263] = 0.004269282;
  azScoreArea[-262] = 0.004396526;
  azScoreArea[-261] = 0.004527147;
  azScoreArea[-260] = 0.004661222;
  azScoreArea[-259] = 0.004798829;
  azScoreArea[-258] = 0.004940046;
  azScoreArea[-257] = 0.005084954;
  azScoreArea[-256] = 0.005233635;
  azScoreArea[-255] = 0.00538617;
  azScoreArea[-254] = 0.005542646;
  azScoreArea[-253] = 0.005703147;
  azScoreArea[-252] = 0.00586776;
  azScoreArea[-251] = 0.006036575;
  azScoreArea[-250] = 0.00620968;
  azScoreArea[-249] = 0.006387167;
  azScoreArea[-248] = 0.006569129;
  azScoreArea[-247] = 0.006755661;
  azScoreArea[-246] = 0.006946857;
  azScoreArea[-245] = 0.007142815;
  azScoreArea[-244] = 0.007343633;
  azScoreArea[-243] = 0.007549411;
  azScoreArea[-242] = 0.007760251;
  azScoreArea[-241] = 0.007976255;
  azScoreArea[-240] = 0.008197529;
  azScoreArea[-239] = 0.008424177;
  azScoreArea[-238] = 0.008656308;
  azScoreArea[-237] = 0.008894029;
  azScoreArea[-236] = 0.009137452;
  azScoreArea[-235] = 0.009386687;
  azScoreArea[-234] = 0.00964185;
  azScoreArea[-233] = 0.009903053;
  azScoreArea[-232] = 0.010170414;
  azScoreArea[-231] = 0.01044405;
  azScoreArea[-230] = 0.010724081;
  azScoreArea[-229] = 0.011010627;
  azScoreArea[-228] = 0.011303811;
  azScoreArea[-227] = 0.011603756;
  azScoreArea[-226] = 0.011910588;
  azScoreArea[-225] = 0.012224433;
  azScoreArea[-224] = 0.01254542;
  azScoreArea[-223] = 0.012873678;
  azScoreArea[-222] = 0.013209339;
  azScoreArea[-221] = 0.013552534;
  azScoreArea[-220] = 0.013903399;
  azScoreArea[-219] = 0.014262068;
  azScoreArea[-218] = 0.014628679;
  azScoreArea[-217] = 0.015003369;
  azScoreArea[-216] = 0.01538628;
  azScoreArea[-215] = 0.015777551;
  azScoreArea[-214] = 0.016177325;
  azScoreArea[-213] = 0.016585747;
  azScoreArea[-212] = 0.017002962;
  azScoreArea[-211] = 0.017429116;
  azScoreArea[-210] = 0.017864357;
  azScoreArea[-209] = 0.018308836;
  azScoreArea[-208] = 0.018762701;
  azScoreArea[-207] = 0.019226106;
  azScoreArea[-206] = 0.019699203;
  azScoreArea[-205] = 0.020182148;
  azScoreArea[-204] = 0.020675095;
  azScoreArea[-203] = 0.021178201;
  azScoreArea[-202] = 0.021691624;
  azScoreArea[-201] = 0.022215525;
  azScoreArea[-200] = 0.022750062;
  azScoreArea[-199] = 0.023295398;
  azScoreArea[-198] = 0.023851694;
  azScoreArea[-197] = 0.024419115;
  azScoreArea[-196] = 0.024997825;
  azScoreArea[-195] = 0.02558799;
  azScoreArea[-194] = 0.026189776;
  azScoreArea[-193] = 0.02680335;
  azScoreArea[-192] = 0.027428881;
  azScoreArea[-191] = 0.028066539;
  azScoreArea[-190] = 0.028716493;
  azScoreArea[-189] = 0.029378914;
  azScoreArea[-188] = 0.030053974;
  azScoreArea[-187] = 0.030741845;
  azScoreArea[-186] = 0.0314427;
  azScoreArea[-185] = 0.032156713;
  azScoreArea[-184] = 0.032884058;
  azScoreArea[-183] = 0.033624911;
  azScoreArea[-182] = 0.034379445;
  azScoreArea[-181] = 0.035147838;
  azScoreArea[-180] = 0.035930266;
  azScoreArea[-179] = 0.036726904;
  azScoreArea[-178] = 0.037537931;
  azScoreArea[-177] = 0.038363523;
  azScoreArea[-176] = 0.039203858;
  azScoreArea[-175] = 0.040059114;
  azScoreArea[-174] = 0.040929468;
  azScoreArea[-173] = 0.041815099;
  azScoreArea[-172] = 0.042716185;
  azScoreArea[-171] = 0.043632903;
  azScoreArea[-170] = 0.044565432;
  azScoreArea[-169] = 0.045513949;
  azScoreArea[-168] = 0.046478632;
  azScoreArea[-167] = 0.047459659;
  azScoreArea[-166] = 0.048457206;
  azScoreArea[-165] = 0.049471451;
  azScoreArea[-164] = 0.050502569;
  azScoreArea[-163] = 0.051550737;
  azScoreArea[-162] = 0.05261613;
  azScoreArea[-161] = 0.053698923;
  azScoreArea[-160] = 0.054799289;
  azScoreArea[-159] = 0.055917403;
  azScoreArea[-158] = 0.057053437;
  azScoreArea[-157] = 0.058207562;
  azScoreArea[-156] = 0.05937995;
  azScoreArea[-155] = 0.060570771;
  azScoreArea[-154] = 0.061780193;
  azScoreArea[-153] = 0.063008383;
  azScoreArea[-152] = 0.06425551;
  azScoreArea[-151] = 0.065521737;
  azScoreArea[-150] = 0.066807229;
  azScoreArea[-149] = 0.068112148;
  azScoreArea[-148] = 0.069436656;
  azScoreArea[-147] = 0.070780913;
  azScoreArea[-146] = 0.072145075;
  azScoreArea[-145] = 0.0735293;
  azScoreArea[-144] = 0.074933743;
  azScoreArea[-143] = 0.076358555;
  azScoreArea[-142] = 0.077803888;
  azScoreArea[-141] = 0.079269891;
  azScoreArea[-140] = 0.080756711;
  azScoreArea[-139] = 0.082264493;
  azScoreArea[-138] = 0.083793378;
  azScoreArea[-137] = 0.085343508;
  azScoreArea[-136] = 0.086915021;
  azScoreArea[-135] = 0.088508052;
  azScoreArea[-134] = 0.090122734;
  azScoreArea[-133] = 0.091759198;
  azScoreArea[-132] = 0.093417573;
  azScoreArea[-131] = 0.095097982;
  azScoreArea[-130] = 0.096800549;
  azScoreArea[-129] = 0.098525394;
  azScoreArea[-128] = 0.100272634;
  azScoreArea[-127] = 0.102042381;
  azScoreArea[-126] = 0.103834747;
  azScoreArea[-125] = 0.105649839;
  azScoreArea[-124] = 0.107487762;
  azScoreArea[-123] = 0.109348617;
  azScoreArea[-122] = 0.111232501;
  azScoreArea[-121] = 0.113139509;
  azScoreArea[-120] = 0.115069732;
  azScoreArea[-119] = 0.117023256;
  azScoreArea[-118] = 0.119000166;
  azScoreArea[-117] = 0.121000541;
  azScoreArea[-116] = 0.123024458;
  azScoreArea[-115] = 0.125071989;
  azScoreArea[-114] = 0.127143201;
  azScoreArea[-113] = 0.129238161;
  azScoreArea[-112] = 0.131356927;
  azScoreArea[-111] = 0.133499557;
  azScoreArea[-110] = 0.135666102;
  azScoreArea[-109] = 0.13785661;
  azScoreArea[-108] = 0.140071125;
  azScoreArea[-107] = 0.142309686;
  azScoreArea[-106] = 0.144572328;
  azScoreArea[-105] = 0.146859081;
  azScoreArea[-104] = 0.149169971;
  azScoreArea[-103] = 0.15150502;
  azScoreArea[-102] = 0.153864244;
  azScoreArea[-101] = 0.156247655;
  azScoreArea[-100] = 0.15865526;
  azScoreArea[-99] = 0.161087061;
  azScoreArea[-98] = 0.163543057;
  azScoreArea[-97] = 0.16602324;
  azScoreArea[-96] = 0.168527597;
  azScoreArea[-95] = 0.171056112;
  azScoreArea[-94] = 0.173608762;
  azScoreArea[-93] = 0.17618552;
  azScoreArea[-92] = 0.178786354;
  azScoreArea[-91] = 0.181411225;
  azScoreArea[-90] = 0.184060092;
  azScoreArea[-89] = 0.186732906;
  azScoreArea[-88] = 0.189429614;
  azScoreArea[-87] = 0.192150158;
  azScoreArea[-86] = 0.194894473;
  azScoreArea[-85] = 0.197662492;
  azScoreArea[-84] = 0.200454139;
  azScoreArea[-83] = 0.203269335;
  azScoreArea[-82] = 0.206107994;
  azScoreArea[-81] = 0.208970026;
  azScoreArea[-80] = 0.211855334;
  azScoreArea[-79] = 0.214763817;
  azScoreArea[-78] = 0.217695369;
  azScoreArea[-77] = 0.220649876;
  azScoreArea[-76] = 0.223627221;
  azScoreArea[-75] = 0.22662728;
  azScoreArea[-74] = 0.229649924;
  azScoreArea[-73] = 0.232695018;
  azScoreArea[-72] = 0.235762424;
  azScoreArea[-71] = 0.238851994;
  azScoreArea[-70] = 0.241963578;
  azScoreArea[-69] = 0.245097021;
  azScoreArea[-68] = 0.248252158;
  azScoreArea[-67] = 0.251428824;
  azScoreArea[-66] = 0.254626846;
  azScoreArea[-65] = 0.257846044;
  azScoreArea[-64] = 0.261086235;
  azScoreArea[-63] = 0.26434723;
  azScoreArea[-62] = 0.267628834;
  azScoreArea[-61] = 0.270930848;
  azScoreArea[-60] = 0.274253065;
  azScoreArea[-59] = 0.277595276;
  azScoreArea[-58] = 0.280957264;
  azScoreArea[-57] = 0.284338808;
  azScoreArea[-56] = 0.287739682;
  azScoreArea[-55] = 0.291159655;
  azScoreArea[-54] = 0.294598489;
  azScoreArea[-53] = 0.298055944;
  azScoreArea[-52] = 0.301531771;
  azScoreArea[-51] = 0.305025719;
  azScoreArea[-50] = 0.308537533;
  azScoreArea[-49] = 0.312066949;
  azScoreArea[-48] = 0.315613701;
  azScoreArea[-47] = 0.319177519;
  azScoreArea[-46] = 0.322758126;
  azScoreArea[-45] = 0.326355241;
  azScoreArea[-44] = 0.32996858;
  azScoreArea[-43] = 0.333597852;
  azScoreArea[-42] = 0.337242763;
  azScoreArea[-41] = 0.340903014;
  azScoreArea[-40] = 0.344578303;
  azScoreArea[-39] = 0.348268323;
  azScoreArea[-38] = 0.35197276;
  azScoreArea[-37] = 0.355691301;
  azScoreArea[-36] = 0.359423626;
  azScoreArea[-35] = 0.36316941;
  azScoreArea[-34] = 0.366928327;
  azScoreArea[-33] = 0.370700045;
  azScoreArea[-32] = 0.37448423;
  azScoreArea[-31] = 0.378280543;
  azScoreArea[-30] = 0.382088643;
  azScoreArea[-29] = 0.385908182;
  azScoreArea[-28] = 0.389738814;
  azScoreArea[-27] = 0.393580186;
  azScoreArea[-26] = 0.397431943;
  azScoreArea[-25] = 0.401293726;
  azScoreArea[-24] = 0.405165176;
  azScoreArea[-23] = 0.409045927;
  azScoreArea[-22] = 0.412935613;
  azScoreArea[-21] = 0.416833866;
  azScoreArea[-20] = 0.420740313;
  azScoreArea[-19] = 0.42465458;
  azScoreArea[-18] = 0.428576291;
  azScoreArea[-17] = 0.432505067;
  azScoreArea[-16] = 0.436440527;
  azScoreArea[-15] = 0.440382288;
  azScoreArea[-14] = 0.444329967;
  azScoreArea[-13] = 0.448283177;
  azScoreArea[-12] = 0.45224153;
  azScoreArea[-11] = 0.456204636;
  azScoreArea[-10] = 0.460172104;
  azScoreArea[-9] = 0.464143544;
  azScoreArea[-8] = 0.46811856;
  azScoreArea[-7] = 0.47209676;
  azScoreArea[-6] = 0.476077747;
  azScoreArea[-5] = 0.480061127;
  azScoreArea[-4] = 0.484046501;
  azScoreArea[-3] = 0.488033473;
  azScoreArea[-2] = 0.492021646;
  azScoreArea[-1] = 0.496010621;
  azScoreArea[0] = 0.5;
  azScoreArea[1] = 0.503989379;
  azScoreArea[2] = 0.507978354;
  azScoreArea[3] = 0.511966527;
  azScoreArea[4] = 0.515953499;
  azScoreArea[5] = 0.519938873;
  azScoreArea[6] = 0.523922253;
  azScoreArea[7] = 0.52790324;
  azScoreArea[8] = 0.53188144;
  azScoreArea[9] = 0.535856456;
  azScoreArea[10] = 0.539827896;
  azScoreArea[11] = 0.543795364;
  azScoreArea[12] = 0.54775847;
  azScoreArea[13] = 0.551716823;
  azScoreArea[14] = 0.555670033;
  azScoreArea[15] = 0.559617712;
  azScoreArea[16] = 0.563559473;
  azScoreArea[17] = 0.567494933;
  azScoreArea[18] = 0.571423709;
  azScoreArea[19] = 0.57534542;
  azScoreArea[20] = 0.579259687;
  azScoreArea[21] = 0.583166134;
  azScoreArea[22] = 0.587064387;
  azScoreArea[23] = 0.590954073;
  azScoreArea[24] = 0.594834824;
  azScoreArea[25] = 0.598706274;
  azScoreArea[26] = 0.602568057;
  azScoreArea[27] = 0.606419814;
  azScoreArea[28] = 0.610261186;
  azScoreArea[29] = 0.614091818;
  azScoreArea[30] = 0.617911357;
  azScoreArea[31] = 0.621719457;
  azScoreArea[32] = 0.62551577;
  azScoreArea[33] = 0.629299955;
  azScoreArea[34] = 0.633071673;
  azScoreArea[35] = 0.63683059;
  azScoreArea[36] = 0.640576374;
  azScoreArea[37] = 0.644308699;
  azScoreArea[38] = 0.64802724;
  azScoreArea[39] = 0.651731677;
  azScoreArea[40] = 0.655421697;
  azScoreArea[41] = 0.659096986;
  azScoreArea[42] = 0.662757237;
  azScoreArea[43] = 0.666402148;
  azScoreArea[44] = 0.67003142;
  azScoreArea[45] = 0.673644759;
  azScoreArea[46] = 0.677241874;
  azScoreArea[47] = 0.680822481;
  azScoreArea[48] = 0.684386299;
  azScoreArea[49] = 0.687933051;
  azScoreArea[50] = 0.691462467;
  azScoreArea[51] = 0.694974281;
  azScoreArea[52] = 0.698468229;
  azScoreArea[53] = 0.701944056;
  azScoreArea[54] = 0.705401511;
  azScoreArea[55] = 0.708840345;
  azScoreArea[56] = 0.712260318;
  azScoreArea[57] = 0.715661192;
  azScoreArea[58] = 0.719042736;
  azScoreArea[59] = 0.722404724;
  azScoreArea[60] = 0.725746935;
  azScoreArea[61] = 0.729069152;
  azScoreArea[62] = 0.732371166;
  azScoreArea[63] = 0.73565277;
  azScoreArea[64] = 0.738913765;
  azScoreArea[65] = 0.742153956;
  azScoreArea[66] = 0.745373154;
  azScoreArea[67] = 0.748571176;
  azScoreArea[68] = 0.751747842;
  azScoreArea[69] = 0.754902979;
  azScoreArea[70] = 0.758036422;
  azScoreArea[71] = 0.761148006;
  azScoreArea[72] = 0.764237576;
  azScoreArea[73] = 0.767304982;
  azScoreArea[74] = 0.770350076;
  azScoreArea[75] = 0.77337272;
  azScoreArea[76] = 0.776372779;
  azScoreArea[77] = 0.779350124;
  azScoreArea[78] = 0.782304631;
  azScoreArea[79] = 0.785236183;
  azScoreArea[80] = 0.788144666;
  azScoreArea[81] = 0.791029974;
  azScoreArea[82] = 0.793892006;
  azScoreArea[83] = 0.796730665;
  azScoreArea[84] = 0.799545861;
  azScoreArea[85] = 0.802337508;
  azScoreArea[86] = 0.805105527;
  azScoreArea[87] = 0.807849842;
  azScoreArea[88] = 0.810570386;
  azScoreArea[89] = 0.813267094;
  azScoreArea[90] = 0.815939908;
  azScoreArea[91] = 0.818588775;
  azScoreArea[92] = 0.821213646;
  azScoreArea[93] = 0.82381448;
  azScoreArea[94] = 0.826391238;
  azScoreArea[95] = 0.828943888;
  azScoreArea[96] = 0.831472403;
  azScoreArea[97] = 0.83397676;
  azScoreArea[98] = 0.836456943;
  azScoreArea[99] = 0.838912939;
  azScoreArea[100] = 0.84134474;
  azScoreArea[101] = 0.843752345;
  azScoreArea[102] = 0.846135756;
  azScoreArea[103] = 0.84849498;
  azScoreArea[104] = 0.850830029;
  azScoreArea[105] = 0.853140919;
  azScoreArea[106] = 0.855427672;
  azScoreArea[107] = 0.857690314;
  azScoreArea[108] = 0.859928875;
  azScoreArea[109] = 0.86214339;
  azScoreArea[110] = 0.864333898;
  azScoreArea[111] = 0.866500443;
  azScoreArea[112] = 0.868643073;
  azScoreArea[113] = 0.870761839;
  azScoreArea[114] = 0.872856799;
  azScoreArea[115] = 0.874928011;
  azScoreArea[116] = 0.876975542;
  azScoreArea[117] = 0.878999459;
  azScoreArea[118] = 0.880999834;
  azScoreArea[119] = 0.882976744;
  azScoreArea[120] = 0.884930268;
  azScoreArea[121] = 0.886860491;
  azScoreArea[122] = 0.888767499;
  azScoreArea[123] = 0.890651383;
  azScoreArea[124] = 0.892512238;
  azScoreArea[125] = 0.894350161;
  azScoreArea[126] = 0.896165253;
  azScoreArea[127] = 0.897957619;
  azScoreArea[128] = 0.899727366;
  azScoreArea[129] = 0.901474606;
  azScoreArea[130] = 0.903199451;
  azScoreArea[131] = 0.904902018;
  azScoreArea[132] = 0.906582427;
  azScoreArea[133] = 0.908240802;
  azScoreArea[134] = 0.909877266;
  azScoreArea[135] = 0.911491948;
  azScoreArea[136] = 0.913084979;
  azScoreArea[137] = 0.914656492;
  azScoreArea[138] = 0.916206622;
  azScoreArea[139] = 0.917735507;
  azScoreArea[140] = 0.919243289;
  azScoreArea[141] = 0.920730109;
  azScoreArea[142] = 0.922196112;
  azScoreArea[143] = 0.923641445;
  azScoreArea[144] = 0.925066257;
  azScoreArea[145] = 0.9264707;
  azScoreArea[146] = 0.927854925;
  azScoreArea[147] = 0.929219087;
  azScoreArea[148] = 0.930563344;
  azScoreArea[149] = 0.931887852;
  azScoreArea[150] = 0.933192771;
  azScoreArea[151] = 0.934478263;
  azScoreArea[152] = 0.93574449;
  azScoreArea[153] = 0.936991617;
  azScoreArea[154] = 0.938219807;
  azScoreArea[155] = 0.939429229;
  azScoreArea[156] = 0.94062005;
  azScoreArea[157] = 0.941792438;
  azScoreArea[158] = 0.942946563;
  azScoreArea[159] = 0.944082597;
  azScoreArea[160] = 0.945200711;
  azScoreArea[161] = 0.946301077;
  azScoreArea[162] = 0.94738387;
  azScoreArea[163] = 0.948449263;
  azScoreArea[164] = 0.949497431;
  azScoreArea[165] = 0.950528549;
  azScoreArea[166] = 0.951542794;
  azScoreArea[167] = 0.952540341;
  azScoreArea[168] = 0.953521368;
  azScoreArea[169] = 0.954486051;
  azScoreArea[170] = 0.955434568;
  azScoreArea[171] = 0.956367097;
  azScoreArea[172] = 0.957283815;
  azScoreArea[173] = 0.958184901;
  azScoreArea[174] = 0.959070532;
  azScoreArea[175] = 0.959940886;
  azScoreArea[176] = 0.960796142;
  azScoreArea[177] = 0.961636477;
  azScoreArea[178] = 0.962462069;
  azScoreArea[179] = 0.963273096;
  azScoreArea[180] = 0.964069734;
  azScoreArea[181] = 0.964852162;
  azScoreArea[182] = 0.965620555;
  azScoreArea[183] = 0.966375089;
  azScoreArea[184] = 0.967115942;
  azScoreArea[185] = 0.967843287;
  azScoreArea[186] = 0.9685573;
  azScoreArea[187] = 0.969258155;
  azScoreArea[188] = 0.969946026;
  azScoreArea[189] = 0.970621086;
  azScoreArea[190] = 0.971283507;
  azScoreArea[191] = 0.971933461;
  azScoreArea[192] = 0.972571119;
  azScoreArea[193] = 0.97319665;
  azScoreArea[194] = 0.973810224;
  azScoreArea[195] = 0.97441201;
  azScoreArea[196] = 0.975002175;
  azScoreArea[197] = 0.975580885;
  azScoreArea[198] = 0.976148306;
  azScoreArea[199] = 0.976704602;
  azScoreArea[200] = 0.977249938;
  azScoreArea[201] = 0.977784475;
  azScoreArea[202] = 0.978308376;
  azScoreArea[203] = 0.978821799;
  azScoreArea[204] = 0.979324905;
  azScoreArea[205] = 0.979817852;
  azScoreArea[206] = 0.980300797;
  azScoreArea[207] = 0.980773894;
  azScoreArea[208] = 0.981237299;
  azScoreArea[209] = 0.981691164;
  azScoreArea[210] = 0.982135643;
  azScoreArea[211] = 0.982570884;
  azScoreArea[212] = 0.982997038;
  azScoreArea[213] = 0.983414253;
  azScoreArea[214] = 0.983822675;
  azScoreArea[215] = 0.984222449;
  azScoreArea[216] = 0.98461372;
  azScoreArea[217] = 0.984996631;
  azScoreArea[218] = 0.985371321;
  azScoreArea[219] = 0.985737932;
  azScoreArea[220] = 0.986096601;
  azScoreArea[221] = 0.986447466;
  azScoreArea[222] = 0.986790661;
  azScoreArea[223] = 0.987126322;
  azScoreArea[224] = 0.98745458;
  azScoreArea[225] = 0.987775567;
  azScoreArea[226] = 0.988089412;
  azScoreArea[227] = 0.988396244;
  azScoreArea[228] = 0.988696189;
  azScoreArea[229] = 0.988989373;
  azScoreArea[230] = 0.989275919;
  azScoreArea[231] = 0.98955595;
  azScoreArea[232] = 0.989829586;
  azScoreArea[233] = 0.990096947;
  azScoreArea[234] = 0.99035815;
  azScoreArea[235] = 0.990613313;
  azScoreArea[236] = 0.990862548;
  azScoreArea[237] = 0.991105971;
  azScoreArea[238] = 0.991343692;
  azScoreArea[239] = 0.991575823;
  azScoreArea[240] = 0.991802471;
  azScoreArea[241] = 0.992023745;
  azScoreArea[242] = 0.992239749;
  azScoreArea[243] = 0.992450589;
  azScoreArea[244] = 0.992656367;
  azScoreArea[245] = 0.992857185;
  azScoreArea[246] = 0.993053143;
  azScoreArea[247] = 0.993244339;
  azScoreArea[248] = 0.993430871;
  azScoreArea[249] = 0.993612833;
  azScoreArea[250] = 0.99379032;
  azScoreArea[251] = 0.993963425;
  azScoreArea[252] = 0.99413224;
  azScoreArea[253] = 0.994296853;
  azScoreArea[254] = 0.994457354;
  azScoreArea[255] = 0.99461383;
  azScoreArea[256] = 0.994766365;
  azScoreArea[257] = 0.994915046;
  azScoreArea[258] = 0.995059954;
  azScoreArea[259] = 0.995201171;
  azScoreArea[260] = 0.995338778;
  azScoreArea[261] = 0.995472853;
  azScoreArea[262] = 0.995603474;
  azScoreArea[263] = 0.995730718;
  azScoreArea[264] = 0.995854658;
  azScoreArea[265] = 0.995975369;
  azScoreArea[266] = 0.996092924;
  azScoreArea[267] = 0.996207393;
  azScoreArea[268] = 0.996318845;
  azScoreArea[269] = 0.996427351;
  azScoreArea[270] = 0.996532977;
  azScoreArea[271] = 0.996635789;
  azScoreArea[272] = 0.996735852;
  azScoreArea[273] = 0.996833231;
  azScoreArea[274] = 0.996927987;
  azScoreArea[275] = 0.997020181;
  azScoreArea[276] = 0.997109875;
  azScoreArea[277] = 0.997197128;
  azScoreArea[278] = 0.997281997;
  azScoreArea[279] = 0.997364539;
  azScoreArea[280] = 0.997444809;
  azScoreArea[281] = 0.997522864;
  azScoreArea[282] = 0.997598756;
  azScoreArea[283] = 0.997672537;
  azScoreArea[284] = 0.99774426;
  azScoreArea[285] = 0.997813974;
  azScoreArea[286] = 0.99788173;
  azScoreArea[287] = 0.997947576;
  azScoreArea[288] = 0.998011558;
  azScoreArea[289] = 0.998073724;
  azScoreArea[290] = 0.99813412;
  azScoreArea[291] = 0.998192789;
  azScoreArea[292] = 0.998249775;
  azScoreArea[293] = 0.998305122;
  azScoreArea[294] = 0.998358871;
  azScoreArea[295] = 0.998411062;
  azScoreArea[296] = 0.998461736;
  azScoreArea[297] = 0.998510932;
  azScoreArea[298] = 0.998558689;
  azScoreArea[299] = 0.998605044;
  azScoreArea[300] = 0.998650033;
  azScoreArea[301] = 0.998693692;
  azScoreArea[302] = 0.998736057;
  azScoreArea[303] = 0.998777162;
  azScoreArea[304] = 0.99881704;
  azScoreArea[305] = 0.998855724;
  azScoreArea[306] = 0.998893246;
  azScoreArea[307] = 0.998929637;
  azScoreArea[308] = 0.998964929;
  azScoreArea[309] = 0.998999149;
  azScoreArea[310] = 0.999032329;
  azScoreArea[311] = 0.999064496;
  azScoreArea[312] = 0.999095677;
  azScoreArea[313] = 0.999125901;
  azScoreArea[314] = 0.999155194;
  azScoreArea[315] = 0.999183581;
  azScoreArea[316] = 0.999211088;
  azScoreArea[317] = 0.99923774;
  azScoreArea[318] = 0.99926356;
  azScoreArea[319] = 0.999288571;
  azScoreArea[320] = 0.999312798;
  azScoreArea[321] = 0.999336262;
  azScoreArea[322] = 0.999358984;
  azScoreArea[323] = 0.999380986;
  azScoreArea[324] = 0.999402289;
  azScoreArea[325] = 0.999422914;
  azScoreArea[326] = 0.999442878;
  azScoreArea[327] = 0.999462202;
  azScoreArea[328] = 0.999480905;
  azScoreArea[329] = 0.999499004;
  azScoreArea[330] = 0.999516517;
  azScoreArea[331] = 0.999533462;
  azScoreArea[332] = 0.999549856;
  azScoreArea[333] = 0.999565714;
  azScoreArea[334] = 0.999581052;
  azScoreArea[335] = 0.999595887;
  azScoreArea[336] = 0.999610233;
  azScoreArea[337] = 0.999624105;
  azScoreArea[338] = 0.999637518;
  azScoreArea[339] = 0.999650485;
  azScoreArea[340] = 0.999663019;
  azScoreArea[341] = 0.999675135;
  azScoreArea[342] = 0.999686844;
  azScoreArea[343] = 0.99969816;
  azScoreArea[344] = 0.999709094;
  azScoreArea[345] = 0.999719659;
  azScoreArea[346] = 0.999729865;
  azScoreArea[347] = 0.999739724;
  azScoreArea[348] = 0.999749247;
  azScoreArea[349] = 0.999758445;
  azScoreArea[350] = 0.999767327;
  azScoreArea[351] = 0.999775903;
  azScoreArea[352] = 0.999784184;
  azScoreArea[353] = 0.999792178;
  azScoreArea[354] = 0.999799895;
  azScoreArea[355] = 0.999807344;
  azScoreArea[356] = 0.999814533;
  azScoreArea[357] = 0.99982147;
  azScoreArea[358] = 0.999828164;
  azScoreArea[359] = 0.999834623;
  azScoreArea[360] = 0.999840854;
  azScoreArea[361] = 0.999846865;
  azScoreArea[362] = 0.999852663;
  azScoreArea[363] = 0.999858254;
  azScoreArea[364] = 0.999863647;
  azScoreArea[365] = 0.999868846;
  azScoreArea[366] = 0.999873859;
  azScoreArea[367] = 0.999878692;
  azScoreArea[368] = 0.999883351;
  azScoreArea[369] = 0.999887842;
  azScoreArea[370] = 0.99989217;
  azScoreArea[371] = 0.999896341;
  azScoreArea[372] = 0.999900359;
  azScoreArea[373] = 0.999904232;
  azScoreArea[374] = 0.999907962;
  azScoreArea[375] = 0.999911555;
  azScoreArea[376] = 0.999915017;
  azScoreArea[377] = 0.99991835;
  azScoreArea[378] = 0.99992156;
  azScoreArea[379] = 0.999924651;
  azScoreArea[380] = 0.999927628;
  azScoreArea[381] = 0.999930493;
  azScoreArea[382] = 0.999933251;
  azScoreArea[383] = 0.999935906;
  azScoreArea[384] = 0.999938461;
  azScoreArea[385] = 0.999940919;
  azScoreArea[386] = 0.999943285;
  azScoreArea[387] = 0.999945562;
  azScoreArea[388] = 0.999947752;
  azScoreArea[389] = 0.999949858;
  azScoreArea[390] = 0.999951884;
  azScoreArea[391] = 0.999953833;
  azScoreArea[392] = 0.999955707;
  azScoreArea[393] = 0.999957509;
  azScoreArea[394] = 0.999959242;
  azScoreArea[395] = 0.999960908;
  azScoreArea[396] = 0.999962509;
  azScoreArea[397] = 0.999964048;
  azScoreArea[398] = 0.999965527;
  azScoreArea[399] = 0.999966948;
  azScoreArea[400] = 0.999968314;

  return azScoreArea;
};

const calculateAgeInMonthsWithTwoDates = (d1, d2) => {
  // var start = new XDate(d1);
  // var end = new XDate(d2);
  // var months = _round(start.diffMonths(end), 1);
  // return months <= 0 ? 0 : months;

  var start = new Date(d1);
  var end = new Date(d2);

  // Calculate the difference in years and months
  var yearsDiff = end.getFullYear() - start.getFullYear();
  var monthsDiff = end.getMonth() - start.getMonth();

  // Convert years to months and add the remaining months
  var totalMonths = yearsDiff * 12 + monthsDiff;

  // Adjust the total months if the end date's day is earlier than the start date's day
  if (end.getDate() < start.getDate()) {
    totalMonths--;
  }

  return totalMonths <= 0 ? 0 : totalMonths;
};

const calculateAgeFromBob = (dob) => {
  var dobNew = new Date(dob);
  var diff_ms = Date.now() - dobNew.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

const todayMinus5Years = function () {
  var myDate = new Date();
  var dayOfMonth = myDate.getDate();
  myDate.setDate(dayOfMonth - 1825);
  return myDate;
};

const toFeetAndInches = function (valueInCms) {
  var realFeet = valueInCms / 30.48;
  var feet = Math.floor(realFeet);
  var inches = _round((realFeet - feet) * 12);
  return [feet, inches];
};

const convertKgsToLbs = function (value) {
  return _round(parseFloat(value * 2.20462), 1);
};

const convertLbsToKgs = function (value) {
  return _round(parseFloat(value * 0.453592), 1);
};

const calculateZScore = function (X, L, M, S) {
  var median = parseFloat(M);
  var coffOfVariation = parseFloat(S);
  var boxCox = parseFloat(L);
  var physicalMeasurement = parseFloat(X);

  if (_isNumber(boxCox)) {
    var NUM = parseFloat(Math.pow(physicalMeasurement / median, boxCox) - 1);
    var DEN = parseFloat(boxCox * coffOfVariation);
    var zScore = parseFloat(NUM / DEN);

    zScore = zScore * 100;
    var l = Math.round(zScore);
    if (l < -400) {
      l = -400;
    } else {
      if (l > 400) {
        l = 400;
      }
    }
    var A = zScoreAreas()[l] * 100;
    return _round(A, 4);
  }
};

const convertInchesToCms = function (value) {
  return _round(value * 2.54, 1);
};

const convertCmsToInches = function (value) {
  return _round(value * 0.393701, 1);
};

const convertCmToFeetInch = (cm) => {
  const cmPerInch = 2.54;
  const inchesPerFoot = 12;
  const totalInches = cm / cmPerInch;
  const totalFeet = Math.floor(totalInches / inchesPerFoot);
  const remainingInches = Math.round(totalInches % inchesPerFoot);
  // setFeet(totalFeet);
  // setInches(remainingInches);
  return {
    feet: totalFeet,
    inch: remainingInches,
  };
};

const convertInToFt = (inches) => {
  const inchesPerFoot = 12;
  const totalFeet = Math.floor(inches / inchesPerFoot);
  const remainingInches = Math.round(inches % inchesPerFoot);
  return {
    feet: totalFeet,
    inch: remainingInches,
  };
};

const convertFeetToCm = (feet, inches) => {
  // const feet = Math.floor(parseFloat(value));
  // const inches = Math.round((parseFloat(value) - feet) * 12);
  const totalInches = feet * 12 + inches;
  const cm = totalInches * 2.54;
  return cm;
};

/*
    function getAge
    parameters: dateString dateType
    returns: boolean

    dateString is a date passed as a string in the following
    formats:

    type 1 : 19970529
    type 2 : 970529
    type 3 : 29/05/1997
    type 4 : 29/05/97

    dateType is a numeric integer from 1 to 4, representing
    the type of dateString passed, as defined above.

    Returns string containing the age in years, months and days
    in the format yyy years mm months dd days.
    Returns empty string if dateType is not one of the expected
    values.
*/
const getAge = function (dateOfBirth, md) {
  var now = new Date(md);
  var dob = new Date(dateOfBirth);

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();

  var yearAge = yearNow - yearDob,
    monthAge,
    dateAge;

  if (monthNow >= monthDob) monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) dateAge = dateNow - dateDob;
  else {
    monthAge--;
    dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  return (
    yearAge +
    ' ' +
    pluralizeCustom('year', yearAge) +
    ', ' +
    monthAge +
    ' ' +
    pluralizeCustom('month', monthAge) +
    ' and ' +
    dateAge +
    ' ' +
    pluralizeCustom('day', dateAge)
  );
};

const getAgeGreater20 = function (dateOfBirth, md) {
  var now = new Date(md);
  var dob = new Date(dateOfBirth);

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();

  var yearAge = yearNow - yearDob,
    monthAge,
    dateAge;

  if (monthNow >= monthDob) monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) dateAge = dateNow - dateDob;
  else {
    monthAge--;
    dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  if (yearAge >= 20 && monthAge > 0) {
    return true;
  } else {
    return false;
  }
};

const convertAllDataPointsByUnit = function (unit, charts) {
  var modified_charts = charts;

  var covert = function (chart) {
    var _chart = chart;

    if (unit === 'inches') {
      _chart.data = _round(parseFloat(chart.data * 0.393701), 1);
    }
    if (unit === 'lbs') {
      _chart.data = _round(parseFloat(_chart.data * 2.20462), 1);
    }
    return _chart;
  };
  modified_charts = _map(charts, covert);

  return modified_charts;
};

const calculateAmericanBmi = function (weight, height) {
  var NUM = parseFloat(weight);
  var DEN = parseFloat(height) * parseFloat(height);
  return parseFloat((NUM / DEN) * 703);
};

const calculateMetricBmi = function (weight, height) {
  var NUM = parseFloat(weight);
  var DEN = parseFloat(height / 100) * parseFloat(height / 100);
  return NUM / DEN;
};

const FormulateChartURLs = function (age, url) {
  let otherLinks = {};
  if (age <= 24) {
    otherLinks.sidebarWeightChartUrl = url.replace(/height|head/g, 'weight');
    otherLinks.sidebarHeightChartUrl = url.replace(/weight|head/g, 'height');
    otherLinks.sidebarHeadChartUrl = url.replace(/height|weight/g, 'head');
    otherLinks.sidebarBMIChartUrl = undefined;
  } else {
    otherLinks.sidebarWeightChartUrl = url.replace(/height|bmi/g, 'weight');
    otherLinks.sidebarHeightChartUrl = url.replace(/weight|bmi/g, 'height');
    otherLinks.sidebarHeadChartUrl = undefined;
    otherLinks.sidebarBMIChartUrl = url.replace(/weight|height/g, 'bmi');
  }

  return otherLinks;
};

const regeneratePath = (currentPath, path) => {
  // const location = useRouter();
  // let currentPath = location.asPath;
  if (
    currentPath?.includes('weight-charts') ||
    currentPath?.includes('height-charts') ||
    currentPath?.includes('head-charts') ||
    currentPath?.includes('bmi-charts')
  ) {
    let temp = currentPath?.split('/');
    temp[1] = path;

    const hasUnit = temp.length > 4;
    if (hasUnit) temp = temp.slice(0, -1);
    return temp.join('/');
  }
  return `/${path}`;
};

const calculateDobPerLocation = function (location) {
  let myDate = new Date();
  const dayOfMonth = myDate.getDate();
  const loc_array = _split(location, '/');
  const lastElement = _last(loc_array);
  const lastElementArray = _split(lastElement, '-');
  let days = 0;

  if (lastElementArray[1] === 'year' || lastElementArray[1] === 'years') {
    days = parseInt(lastElementArray[0]) * 366;
    if (_size(lastElementArray) > 3 && (_last(lastElementArray) === 'month' || _last(lastElementArray) === 'months')) {
      days += parseInt(lastElementArray[2]) * 31;
    }
  } else if (lastElementArray[1] === 'month' || lastElementArray[1] === 'months') {
    days = parseInt(lastElementArray[0]) * 31;
  } else if (lastElementArray[1] === 'week' || lastElementArray[1] === 'weeks') {
    days = parseInt(lastElementArray[0]) * 7;
  } else {
    days = 365;
  }

  myDate.setDate(dayOfMonth - days);
  return myDate;
};

const parseUrlForSimpleChartType = function (splittedUrl) {
  var url_fragment = splittedUrl.split('-')[0];
  var chart_type = '';
  switch (url_fragment) {
    case 'weight':
      chart_type = 'weight';
      break;
    case 'height':
      chart_type = 'height';
      break;
    case 'bmi':
      chart_type = 'BMI';
      break;
    case 'head':
      chart_type = 'head';
      break;
    default:
      chart_type = 'weight';
      break;
  }

  return chart_type;
};

const isGenderMale = (gender) => gender === 'm';
const capitalizeFirstLetter = (string) =>{
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getDateBeforeDays = (days)=> {
  let pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - days); // Subtract days
  
  let formattedDate = pastDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  return formattedDate;
}

export {
  calculateAgeInMonthsWithTwoDates,
  todayMinus5Years,
  convertKgsToLbs,
  convertLbsToKgs,
  toFeetAndInches,
  calculateZScore,
  convertInchesToCms,
  convertCmsToInches,
  convertFeetToCm,
  convertCmToFeetInch,
  convertInToFt,
  getAge,
  convertAllDataPointsByUnit,
  calculateAmericanBmi,
  calculateMetricBmi,
  FormulateChartURLs,
  calculateAgeFromBob,
  regeneratePath,
  getAgeGreater20,
  calculateDobPerLocation,
  isGenderMale,
  parseUrlForSimpleChartType,
  capitalizeFirstLetter,
  getDateBeforeDays
};
